import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      strokeLinejoin='round'
      strokeMiterlimit='2'
      clipRule='evenodd'
      viewBox='0 0 1001 807'
    >
      <path
        fill='#FFF200'
        stroke='#FFF200'
        strokeWidth='1'
        d='M84.547 628.791h117.297l122.89-228.089c-83.297-13.22-146.833-81.712-147.001-164.311-.186-92.213 78.667-167.066 176.08-167.253 2.397 0 4.775.054 7.166.133H520v600.667h-57.067v-543.6H355.6v-.298c-64.175-.775-117.876 45.495-120.853 104.965-3.067 60.72 47.92 112.213 113.866 115.013 1.846.077 3.668.129 5.512.133l.115-.213 38.419 20.699-159.326 295.716v.305H83.536c-6.895 15.097-22.132 25.6-39.803 25.6C19.596 688.258 0 668.662 0 644.525c0-24.138 19.596-43.734 43.733-43.734 18.593 0 34.492 11.628 40.814 28zM12 644.525c0-17.515 14.219-31.734 31.733-31.734 17.514 0 31.734 14.219 31.734 31.734 0 17.514-14.22 31.733-31.734 31.733-17.514 0-31.733-14.219-31.733-31.733zm944.972-101.283l-68.439 124.669-.133-.073v1.407H580.133v-.907h-2.4V68.071H634.8v557.307h238.422l52.219-95.124c-7.814-7.903-12.641-18.764-12.641-30.743 0-24.137 19.596-43.733 43.733-43.733 24.137 0 43.737 19.596 43.737 43.733 0 23.99-19.361 43.494-43.298 43.731zM924.8 499.511c0-17.514 14.219-31.733 31.733-31.733 17.514 0 31.734 14.219 31.734 31.733 0 17.514-14.22 31.734-31.734 31.734-17.514 0-31.733-14.22-31.733-31.734z'
      ></path>
    </svg>
  );
}

export default Icon;
